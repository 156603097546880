import React from "react"
import StyledLink from "./styledlink"
import { IconContext } from "@react-icons/all-files"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"
import homeHeroStyles from "./home-hero.module.scss"

export default function Hero({ children }) {
    const defaultAvatar = './amit.jpeg'

    return (
        <div className="tile is-ancestor">
            <div className="tile is-8">
                <div className="tile is-parent">
                    <article className="tile is-child">
                        <h1 className="title is-2">
                            Hello | Namasté | <span role="img" aria-label="folded-hands-emoji">🙏</span>
                                </h1>
                        <div className="content is-medium">
                            <p>
                                My name is <strong>Amit</strong>. I was born and raised in Phagwara, Punjab, India (city, state, country).
                                The logo on top-left is the <em>Punjabi</em> version of my name.
                            </p>
                            <p>
                                My profession and passion is Software Engineering. I also hold a Business Degree specializing in Finance.
                            </p>
                            <p>
                                These days I spend my time exploring cloud technologies, building cloud native applications, 
                                practicing software architecture and systems design, doing data engineering and infrastructure stuff. 
                            </p>
                            <p>
                                If you want to know more about my areas of interest, 
                                check out my <StyledLink to="/blog"><u>Blog</u></StyledLink>.
                            </p>
                            <p>
                                Let's <a
                                        className={homeHeroStyles.link}
                                        href="https://www.linkedin.com/in/amit-malhotra-134b0132/" target="_blank"
                                        rel="noreferrer">
                                    <u>connect!</u>
                                </a>
                            </p>
                        </div>
                    </article>
                </div>
            </div>
            <div className="level tile is-vertical is-parent">
                <article className="level-item tile is-flex is-child">
                    <figure className="image is-128x128">
                        <img className={`is-rounded ${homeHeroStyles.flip}`} alt="Amit Malhotra" src={defaultAvatar} />
                    </figure>
                </article>
                <article className="level-item tile has-text-centered">
                    <div className="tile is-parent is-vertical">
                        <article className="tile is-child">
                            <p className="subtitle is-size-6"><strong>Amit Malhotra</strong></p>
                            <p className="subtitle is-size-6">Software Engineer</p>

                        </article>
                        <article className="tile is-child">
                            <IconContext.Provider value={{ size: "1.75em", color: "#1d3557" }}>
                                <a href="https://www.linkedin.com/in/amit-malhotra-134b0132/" target="_blank" rel="noreferrer">
                                    <span className="icon is-large">
                                        <FaLinkedin />
                                    </span>
                                </a>
                                <a href="https://github.com/malhotraamit236" target="_blank" rel="noreferrer">
                                    <span className="icon is-large">
                                        <FaGithub />
                                    </span>
                                </a>
                            </IconContext.Provider>
                        </article>
                        <article className="tile is-child">
                            <p className="subtitle is-size-6 pb-2"><u>Interested in</u></p>
                            <div className="tags is-centered">
                                <span className="tag is-primary is-normal">Engineering</span>
                                <span className="tag is-primary is-normal">Systems design</span>
                                <span className="tag is-primary is-normal">Cloud technologies</span>
                                <span className="tag is-primary is-normal">Infrastructure</span>
                                <span className="tag is-primary is-normal">Data</span>
                                <span className="tag is-primary is-normal">Event based systems</span>
                            </div>
                            <p className="subtitle is-size-6 pb-2"><u>Also into</u></p>
                            <IconContext.Provider value={{ size: "1.75em" }}>
                                <div className="tags is-centered">
                                    <span className="tag is-info is-normal">Food</span>
                                    <span className="tag is-info is-normal">Music</span>                                </div>
                            </IconContext.Provider>
                        </article>
                    </div>
                </article>
            </div>
        </div>
    )
}