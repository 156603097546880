import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/home-hero"
import Helmet from "react-helmet"

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default function Home(props) {
  return (
    <>
      <Helmet title={props.data.site.siteMetadata.title} />
      <Hero />
    </>
  )
}